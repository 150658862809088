import {
  Modal as NextUIModal,
  ModalBody as NextUIModalBody,
  ModalContent as NextUIModalContent,
  ModalFooter as NextUIModalFooter,
  ModalHeader as NextUIModalHeader,
  type ModalBodyProps as NextUIModalBodyProps,
  type ModalContentProps as NextUIModalContentProps,
  type ModalFooterProps as NextUIModalFooterProps,
  type ModalHeaderProps as NextUIModalHeaderProps,
  type ModalProps as NextUIModalProps,
} from '@nextui-org/modal';

type ModalProps = NextUIModalProps;
export const Modal: React.FC<ModalProps> = (props) => {
  return <NextUIModal {...props} />;
};

type ModalContentProps = NextUIModalContentProps;
export const ModalContent: React.FC<ModalContentProps> = (props) => {
  return <NextUIModalContent {...props} />;
};

type ModalHeaderProps = NextUIModalHeaderProps;
export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  return <NextUIModalHeader {...props} />;
};

type ModalBodyProps = NextUIModalBodyProps;
export const ModalBody: React.FC<ModalBodyProps> = (props) => {
  return <NextUIModalBody {...props} />;
};

type ModalFooterProps = NextUIModalFooterProps;
export const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  return <NextUIModalFooter {...props} />;
};
