import {
  Button as NextUIButton,
  ButtonGroup as NextUIButtonGroup,
  type ButtonGroupProps as NextUIButtonGroupProps,
  type ButtonProps as NextUIButtonProps,
} from '@nextui-org/button';

type ButtonProps = NextUIButtonProps;
export const Button: React.FC<ButtonProps> = (props) => {
  return <NextUIButton {...props} />;
};

type ButtonGroupProps = NextUIButtonGroupProps;
export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  return <NextUIButtonGroup {...props} />;
};
