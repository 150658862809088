import {
  Pagination as NextUIPagination,
  PaginationCursor as NextUIPaginationCursor,
  PaginationItem as NextUIPaginationItem,
  type PaginationCursorProps as NextUIPaginationCursorProps,
  type PaginationItemProps as NextUIPaginationItemProps,
  type PaginationProps as NextUIPaginationProps,
} from '@nextui-org/pagination';

type PaginationProps = NextUIPaginationProps;
export const Pagination: React.FC<PaginationProps> = (props) => {
  return <NextUIPagination {...props} />;
};

type PaginationItemProps = NextUIPaginationItemProps;
export const PaginationItem: React.FC<PaginationItemProps> = (props) => {
  return <NextUIPaginationItem {...props} />;
};

type PaginationCursorProps = NextUIPaginationCursorProps;
export const PaginationCursor: React.FC<PaginationCursorProps> = (props) => {
  return <NextUIPaginationCursor {...props} />;
};
