import {
  Accordion as NextUIAccordion,
  AccordionItem as NextUIAccordionItem,
  type AccordionItemProps as NextUIAccordionItemProps,
  type AccordionProps as NextUIAccordionProps,
} from '@nextui-org/accordion';

type AccordionProps = NextUIAccordionProps;
export const Accordion: React.FC<AccordionProps> = (props) => {
  return <NextUIAccordion {...props} />;
};

type AccordionItemProps = NextUIAccordionItemProps;
export const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  return <NextUIAccordionItem {...props} />;
};
