import {
  Snippet as NextUISnippet,
  type SnippetProps as NextUISnippetProps,
} from '@nextui-org/snippet';

type SnippetProps = NextUISnippetProps;

export const Snippet: React.FC<SnippetProps> = (props) => {
  return <NextUISnippet {...props} />;
};
