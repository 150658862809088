import {
  BreadcrumbItem as NextUIBreadcrumbItem,
  Breadcrumbs as NextUIBreadcrumbs,
  type BreadcrumbItemProps as NextUIBreadcrumbItemProps,
  type BreadcrumbsProps as NextUIBreadcrumbsProps,
} from '@nextui-org/breadcrumbs';

type BreadcrumbsProps = NextUIBreadcrumbsProps;
export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return <NextUIBreadcrumbs {...props} />;
};

type BreadcrumbItemProps = NextUIBreadcrumbItemProps;
export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  return <NextUIBreadcrumbItem {...props} />;
};
