import {
  DatePicker as NextUIDatePicker,
  type DatePickerProps as NextUIDatePickerProps,
} from '@nextui-org/date-picker';
import type { DateValue } from '@nextui-org/react';

type DatePickerProps<T extends DateValue = DateValue> =
  NextUIDatePickerProps<T>;
export const DatePicker: React.FC<DatePickerProps> = (props) => {
  return <NextUIDatePicker {...props} />;
};
