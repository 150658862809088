import { SalesPortLogo } from '#SalesPortLogo/index';

/**
 * The header component for the SalesPort extension application used by layout and error screen.
 * Renders the SalesPort logo.
 */
export const AppHeader: React.FC = () => {
  return (
    <div style={containerStyle}>
      <SalesPortLogo />
    </div>
  );
};
const containerStyle: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  paddingTop: 8,
  paddingBottom: 40,
  paddingLeft: 4,
};
