import {
  DateRangePicker as NextUIDateRangePicker,
  type DateRangePickerProps as NextUIDateRangePickerProps,
} from '@nextui-org/date-picker';
import type { DateValue } from '@nextui-org/react';

type DateRangePickerProps<T extends DateValue = DateValue> =
  NextUIDateRangePickerProps<T>;
export const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  return <NextUIDateRangePicker {...props} />;
};
