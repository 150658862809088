import {
  Table as NextUITable,
  TableBody as NextUITableBody,
  TableCell as NextUITableCell,
  TableColumn as NextUITableColumn,
  TableHeader as NextUITableHeader,
  TableRow as NextUITableRow,
} from '@nextui-org/table';

export { NextUITable as Table };
export { NextUITableHeader as TableHeader };
export { NextUITableBody as TableBody };
export { NextUITableColumn as TableColumn };
export { NextUITableRow as TableRow };
export { NextUITableCell as TableCell };
