import type { PropsWithChildren } from 'react';

import {
  Card as NextUICard,
  CardBody as NextUICardBody,
  CardFooter as NextUICardFooter,
  CardHeader as NextUICardHeader,
  type CardFooterProps as NextUICardFooterProps,
  type CardProps as NextUICardProps,
} from '@nextui-org/card';

type CardProps = NextUICardProps;
export const Card: React.FC<CardProps> = (props) => {
  return <NextUICard {...props} />;
};

export const CardHeader: React.FC<PropsWithChildren> = (props) => {
  return <NextUICardHeader {...props} />;
};

export const CardBody: React.FC<PropsWithChildren> = (props) => {
  return <NextUICardBody {...props} />;
};

type CardFooterProps = NextUICardFooterProps;
export const CardFooter: React.FC<CardFooterProps> = (props) => {
  return <NextUICardFooter {...props} />;
};
