import {
  DateInput as NextUIDateInput,
  type DateInputProps as NextUIDateInputProps,
} from '@nextui-org/date-input';
import type { DateValue } from '@nextui-org/react';

type DateInputProps<T extends DateValue = DateValue> = NextUIDateInputProps<T>;
export const DateInput: React.FC<DateInputProps> = (props) => {
  return <NextUIDateInput {...props} />;
};
