import {
  Radio as NextUIRadio,
  RadioGroup as NextUIRadioGroup,
  type RadioGroupProps as NextUIRadioGroupProps,
  type RadioProps as NextUIRadioProps,
} from '@nextui-org/radio';

type RadioGroupProps = NextUIRadioGroupProps;
type RadioProps = NextUIRadioProps;

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  return <NextUIRadioGroup {...props} />;
};

export const Radio: React.FC<RadioProps> = (props) => {
  return <NextUIRadio {...props} />;
};
