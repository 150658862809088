import {
  type SelectedItems as NextUISelectedItems,
  type Selection as NextUISelection,
  type SortDescriptor as NextUISortDescriptor,
} from '@nextui-org/react';
import {
  Select as NextUISelect,
  SelectItem as NextUISelectItem,
  SelectSection as NextUISelectSection,
} from '@nextui-org/select';

export {
  NextUISelect as Select,
  NextUISelectItem as SelectItem,
  type NextUISelectSection as SelectSection,
  type NextUISelectedItems as SelectedItems,
  type NextUISelection as Selection,
  type NextUISortDescriptor as SortDescriptor,
};
