type ErrorScreenProps = {
  header?: string;
  description?: string;
  actionButton?: React.ReactNode;
  footer?: React.ReactNode;
};
export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  header = 'Error',
  description = 'There are some problems with your operation.',
  actionButton,
  footer,
}) => {
  return (
    <div className="flex h-full flex-col content-center items-center justify-center px-4 text-center">
      <p className="text-xl">{header}</p>
      <p className="mt-2 text-sm text-zinc-500">{description}</p>
      {actionButton != null && <div className="mt-4">{actionButton}</div>}
      {footer != null && <div className="mt-4">{footer}</div>}
    </div>
  );
};
