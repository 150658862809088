import React, { type PropsWithChildren } from 'react';

import { Chip } from '#Chip/index';
import { Link } from '#Link/index';

export const PendingChip: React.FC = () => (
  <PrimaryLightChip>Pending...</PrimaryLightChip>
);
export const NotFoundChip: React.FC = () => (
  <PrimaryLightChip>Email Not Found</PrimaryLightChip>
);
export const FoundChip: React.FC<{ email: string }> = ({ email }) => (
  <Link href={`mailto:${email}`} target="_blank">
    <Chip size="md" color="primary">
      {email}
    </Chip>
  </Link>
);

export type PrimaryLightChipProps = PropsWithChildren & { size?: 'sm' | 'md' };
export const PrimaryLightChip: React.FC<PrimaryLightChipProps> = ({
  children,
  size = 'md',
}) => (
  <Chip
    size={size}
    classNames={{
      base: 'bg-blue-50',
      content: 'text-primary',
    }}>
    {children}
  </Chip>
);
