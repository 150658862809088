import {
  Calendar as NextUICalendar,
  type CalendarProps as NextUICalendarProps,
} from '@nextui-org/calendar';
import type { DateValue } from '@nextui-org/react';

type CalendarProps<T extends DateValue = DateValue> = NextUICalendarProps<T>;
export const Calendar: React.FC<CalendarProps> = (props) => {
  return <NextUICalendar {...props} />;
};
