import {
  Autocomplete as NextUIAutocomplete,
  AutocompleteItem as NextUIAutocompleteItem,
  AutocompleteSection as NextUIAutocompleteSection,
  type AutocompleteItemProps as NextUIAutocompleteItemProps,
  type AutocompleteProps as NextUIAutocompleteProps,
  type AutocompleteSectionProps as NextUIAutocompleteSectionProps,
} from '@nextui-org/autocomplete';

type AutocompleteProps<T extends object = object> = NextUIAutocompleteProps<T>;
export const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  return <NextUIAutocomplete {...props} />;
};

type AutocompleteSectionProps = NextUIAutocompleteSectionProps;
export const AutocompleteSection: React.FC<AutocompleteSectionProps> = (
  props,
) => {
  return <NextUIAutocompleteSection {...props} />;
};

type AutocompleteItemProps = NextUIAutocompleteItemProps;
export const AutocompleteItem: React.FC<AutocompleteItemProps> = (props) => {
  return <NextUIAutocompleteItem {...props} />;
};
