import {
  Popover as NextUIPopover,
  PopoverContent as NextUIPopoverContent,
  PopoverTrigger as NextUIPopoverTrigger,
  type PopoverContentProps as NextUIPopoverContentProps,
  type PopoverProps as NextUIPopoverProps,
  type PopoverTriggerProps as NextUIPopoverTriggerProps,
} from '@nextui-org/popover';

type PopoverProps = NextUIPopoverProps;
type PopoverTriggerProps = NextUIPopoverTriggerProps;
type PopoverContentProps = NextUIPopoverContentProps;

export const Popover: React.FC<PopoverProps> = (props) => {
  return <NextUIPopover {...props} />;
};

export const PopoverTrigger: React.FC<PopoverTriggerProps> = (props) => {
  return <NextUIPopoverTrigger {...props} />;
};

export const PopoverContent: React.FC<PopoverContentProps> = (props) => {
  return <NextUIPopoverContent {...props} />;
};
