import {
  Spinner as NextUISpinner,
  type SpinnerProps as NextUISpinnerProps,
} from '@nextui-org/spinner';

type SpinnerProps = NextUISpinnerProps;

export const Spinner: React.FC<SpinnerProps> = (props) => {
  return <NextUISpinner {...props} />;
};
